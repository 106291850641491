
@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-weight: var(--RS-font-weight-variable-bold);
  position: relative;
  padding: 4vh 0 0 0;

  @media (--md-viewport) {
    /* width: auto;
    min-width: calc(var(--RS-space-128) + var(--RS-space-32)); */
  }
}

.RS-list li{
  padding: 0 0 var(--RS-space-16) 0;

  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-p-desktop);
  }

}

.RS-list--tiles{
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  gap: var(--RS-space-32);

  @media (--md-viewport) {
    flex-direction: row;
  }
}

.RS-list--tiles-vertical{
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.RS-list--tiles-no-padding{
  padding: 0;
}



.RS-list--tiles li{
  padding: var(--RS-space-24);
  width: 100%;
  border-radius: var(--RS-space-6);
  /* border-radius: var(--RS-space-6) var(--RS-space-12); */
  
  display: flex;
  flex-direction: column;
  gap: var(--RS-space-8);

  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-lg);

  background-color: var(--RS-white-color);
  box-shadow: inset 0 0 0 1px var(--RS-dark-grey-color);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-p-desktop);
  }
}

.RS-list--tiles li.RS-tile--inverted{
  box-shadow: unset;
  background-color: var(--RS-black-color);
  color: var(--RS-white-color);
}


.RS-list--tiles-no-bg li{
  background-color: unset;
  box-shadow: unset;
}

.RS-list--tiles-alternate-inverted:nth-of-type(odd) li:nth-of-type(odd),
.RS-list--tiles-alternate-inverted:nth-of-type(even) li:nth-of-type(even){
  @media (--md-viewport) {
    box-shadow: unset;
    background-color: var(--RS-black-color);
    color: var(--RS-white-color);
  }
}

.RS-list--tiles-alternate-inverted:nth-of-type(odd) li:nth-of-type(even){
  box-shadow: unset;
  background-color: var(--RS-black-color);
  color: var(--RS-white-color);

  @media (--md-viewport) {
    box-shadow: inset 0 0 0 1px var(--RS-dark-grey-color);
    background-color: var(--RS-white-color);
    color: var(--RS-black-color);
  }
}

.RS-list--tiles-alternate-inverted:nth-of-type(even) li:nth-of-type(even){
  box-shadow: unset;
  background-color: var(--RS-black-color);
  color: var(--RS-white-color);
}

.RS-list--tiles li.RS-tile--highlighted{
  /* box-shadow: inset 0 0 0 1px var(--RS-brand-color-brightest); */
  box-shadow: unset;
  background-color: var(--RS-brand-color);
}


.RS-list--tiles-no-bg li{
  background-color: unset;
}

li.RS-tile--row{
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
}

li.RS-tile--centered{
  align-items: center;
  /* justify-content: center; */
  text-align: center;
}

li.RS-tile--spaced{
  gap: var(--RS-space-12);
  padding: var(--RS-space-32);
}

li.RS-tile--spaced-lg{
  gap: var(--RS-space-32);
  padding: var(--RS-space-16);
}


li.RS-tile--row > div:first-of-type{
  /* width: calc(var(--RS-space-128) + var(--RS-space-32)); */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--RS-space-48);
  margin-right: var(--RS-space-16);
}

.RS-list-icon > svg{
  width: var(--RS-space-32);
  height: var(--RS-space-32);
  /* margin-bottom: var(--RS-space-12); */
  display: inline-flex;
}

.RS-list-icon path{
  fill: var(--RS-black-color);
}


.RS-list--tiles li.RS-tile--inverted path{
  fill: var(--RS-brand-color);
}


.RS-list--tiles-alternate-inverted:nth-of-type(odd) li:nth-of-type(odd) path,
.RS-list--tiles-alternate-inverted:nth-of-type(even) li:nth-of-type(even) path{
  @media(--md-viewport){
    fill: var(--RS-brand-color);
  }
}

.RS-list--tiles-alternate-inverted:nth-of-type(odd) li:nth-of-type(even) path,
.RS-list--tiles-alternate-inverted:nth-of-type(even) li:nth-of-type(even) path{
    fill: var(--RS-brand-color);
    @media(--md-viewport){
      fill: var(--RS-black-color);
    }
}

.RS-list--tiles-alternate-inverted:nth-of-type(even) li:nth-of-type(even) path{
    @media(--md-viewport){
      fill: var(--RS-brand-color);
    }
}

li p{
  z-index: 1;
  position: relative;
}

.RS-tile-disclaimer{
  font-weight: var(--RS-font-weight-variable-regular);
  font-size: var(--RS-font-size-label);
  line-height: var(--RS-line-height-md);
  display: flex;
  margin-top: var(--RS-space-4);
}