/* Global */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

html {
  font-size: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "InstrumentSans-Variable", system-ui, sans-serif;
  font-size: 16px;
  font-weight: var(--RS-font-weight-variable-regular);
  line-height: 1;
  color: var(--RS-black-color);
  letter-spacing: 0;
  background-color: var(--RS-white-color);
  transition: none;
}

body > main {
  width: 100%;
  min-height: 1px;
  overflow: hidden;
}

a{
  -webkit-tap-highlight-color: transparent;
  outline: none;
}


@media screen and (min--moz-device-pixel-ratio:0) {

  /* Visually neglectible rotation added to 'modest' animations which normally result in shaking/jumping effect, FF only */
  :root{
      --RS-rotate-firefox-fix: 0.03deg;
  }

  /* Invisible outline added to prevent skewed/rotated edges to be pixelated/un-antialiased */
  svg,
  path{
     outline: 1px solid rgba(255,255,255,0);
  }

  html{
      scrollbar-width: thin;
  }

  ::-moz-selection {
    color: var(--RS-light-grey-color);
    background: var(--RS-the-red-color);
  }
}

::selection {
  color: var(--RS-white-color);
  background: var(--RS-black-color);
}

body::-webkit-scrollbar{
  width: var(--RS-space-6);
  height: var(--RS-space-4);
  background-color: var(--RS-white-color);
}

body::-webkit-scrollbar-thumb {
  background: var(--RS-dark-grey-color);
  border-radius: 4px;
}

[role="button"] {
  cursor: pointer;
}

svg path,
svg g{
  transform-box: fill-box;
  transform-origin: center;
}

img,
video{
  width: 100%;
  height: auto;
  outline: 1px solid transparent;
}

picture,
img{
  border-radius: inherit;
}


ul{
  list-style: none;
}

.RS-photo--square img{
  aspect-ratio: 1/1;
}

.RS-photo--vertical img{
  aspect-ratio: 0.665/1;
}

.RS-photo--horizontal img{
  aspect-ratio: 1.5/1;
}


