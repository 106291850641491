/* Background */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-background {
  position: fixed;
  width: 100vh;
  height: 100vh;
  top: 33vh;
  left: -10vh;
  pointer-events: none;
  user-select: none;

  --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-32));
  --RS-background-step: 10vh;

  @media (--md-viewport) {
    --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-32));
    --RS-background-step: 25vh;
  }

}

.RS-background--elevated{
  z-index: 667;
}

.RS-background__logo{
  backface-visibility: hidden;
  position: absolute;
  width: calc(var(--RS-orbit-xy) * 0.55);
  height: calc(var(--RS-orbit-xy) * 0.55);
  top: calc(var(--RS-orbit-xy) * 0.275);
  left: calc(var(--RS-orbit-xy) * 0.275);

  @media (--md-viewport) {
    width: calc(var(--RS-orbit-xy) * 0.8);
    height: calc(var(--RS-orbit-xy) * 0.8);
    top: calc(var(--RS-orbit-xy) * 0.1);
    left: calc(var(--RS-orbit-xy) * 0.1);
  }
}


.RS-background__orbit{
  position: absolute;
  border: 2px solid rgba(255,255,255,1);
  border-radius: 50%;
  width: var(--RS-orbit-xy);
  height: var(--RS-orbit-xy);
  opacity: .05;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  backface-visibility: hidden;
  pointer-events: none;
  user-select: none;

  @media (--md-viewport) {
    opacity: .08;
  }
}

.RS-background__orbit--active{
  opacity: .21;

  @media (--md-viewport) {
    opacity: .34;
  }
}

.RS-inner-background-logo{
  opacity: .05;
  transition: opacity var(--RS-anim-duration-lg) var(--RS-default-transition);

  @media (--md-viewport) {
    opacity: .08;
  }
}

.RS-inner-background-logo--active{
  opacity: .13;
}

.RS-background__orbit:nth-of-type(2){
  width: calc(var(--RS-orbit-xy) + var(--RS-background-step)*1);
  left: calc(var(--RS-background-step) * -0.5 * 1);
  height: calc(var(--RS-orbit-xy)+ var(--RS-background-step)*1);
  top: calc(var(--RS-background-step) * -0.5 * 1);
}

.RS-background__orbit:nth-of-type(3){
  width: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 2);
  left: calc(var(--RS-background-step) * -0.5 * 2);
  height: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 2);
  top: calc(var(--RS-background-step) * -0.5 * 2);
}

.RS-background__orbit:nth-of-type(4){
  width: calc(var(--RS-orbit-xy) + var(--RS-background-step) *3);
  left: calc(var(--RS-background-step) * -0.5 * 3);
  height: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 3);
  top: calc(var(--RS-background-step) * -0.5 * 3);
}

.RS-background__orbit:nth-of-type(5){
  width: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 4);
  left: calc(var(--RS-background-step) * -0.5 * 4);
  height: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 4);
  top: calc(var(--RS-background-step) * -0.5 * 4);
}

.RS-background__orbit:nth-of-type(6){
  width: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 5);
  left: calc(var(--RS-background-step) * -0.5 * 5);
  height: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 5);
  top: calc(var(--RS-background-step) * -0.5 * 5);
}

.RS-background__orbit:nth-of-type(7){
  width: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 6);
  left: calc(var(--RS-background-step) * -0.5 * 6);
  height: calc(var(--RS-orbit-xy) + var(--RS-background-step) * 6);
  top: calc(var(--RS-background-step) * -0.5 * 6);
}




.RS-background__orbit-contact{
  --RS-orbit-xy: calc(var(--RS-space-64) + var(--RS-space-32));
  --RS-background-step: 10vh;

  @media (--md-viewport) {
    --RS-orbit-xy: calc(var(--RS-space-64) + var(--RS-space-32));
    --RS-background-step: 10vh;
  }
}

.RS-background__orbit-contact:nth-of-type(2){
  left: initial;
  top: initial;
}

.RS-contact-form-wrapper:hover .RS-background__orbit-contact{
  transform: scale(.88, .86);
}

.RS-background__orbit-contact:nth-of-type(2){
  transition-delay: calc(var(--RS-anim-delay)*1);
}


.RS-background__orbit-video{
  --RS-orbit-xy: calc(var(--RS-space-64) + var(--RS-space-48));
  --RS-background-step: 10vh;
  opacity: .21;
  transform: scale(.88, .88);

  @media (--md-viewport) {
    --RS-orbit-xy: calc(var(--RS-space-256) + var(--RS-space-64));
    --RS-background-step: 15vh;
  }
}

.RS-background__orbit-video:nth-of-type(2){
  left: initial;
  top: initial;
}

.RS-video-overlay:hover .RS-background__orbit-video{
  transform: scale(1,1);
}

.RS-background__orbit-video:nth-of-type(2){
  transition-delay: calc(var(--RS-anim-delay)*1);
}