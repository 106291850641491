/* Animations */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-animation {
  opacity: 0.01;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-xlg);
  transition-property: transform, opacity;
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  transform-style: preserve-3d;
}

.RS-animation__slow{
  transition-duration: var(--RS-anim-duration-2xlg);
}

.RS-animation.RS-animation--enter {
  opacity: 1;
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-top {
  transform: translate3d(0, 8px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-bottom {
  transform: translate3d(0, -13px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-left {
  transform: translate3d(13px, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-right {
  transform: translate3d(-8px, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}


.RS-animation__image-to-right {
  transform: translate3d(89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-left {
  transform: translate3d(-89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-top {
  /* transform: translate3d(0, 34px, 0) scale(.98) rotateX(-45deg) rotate(var(--RS-rotate-firefox-fix)); */
  /* transform-origin: bottom; */
  transform: translate3d(0, 34px, 0) scale(.98) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-bottom {
  transform: translate3d(0, -34px, 0) scale(.98) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-hero {
  transform: translate3d(0, 0, 0) scaleY(.88) scaleX(1) rotate(var(--RS-rotate-firefox-fix));
  transform-origin: center;
}

.RS-stagger--1{
  transition-delay: calc(var(--RS-anim-delay) * 1);
}

.RS-stagger--2{
  transition-delay: calc(var(--RS-anim-delay) * 2);
}

.RS-stagger--3{
  transition-delay: calc(var(--RS-anim-delay) * 3);
}

.RS-stagger--4{
  transition-delay: calc(var(--RS-anim-delay) * 4);
}

.RS-stagger--5{
  transition-delay: calc(var(--RS-anim-delay) * 5);
}

.RS-stagger--6{
  transition-delay: calc(var(--RS-anim-delay) * 6);
}

.RS-stagger--7{
  transition-delay: calc(var(--RS-anim-delay) * 7);
}

.RS-stagger--8{
  transition-delay: calc(var(--RS-anim-delay) * 8);
}

.RS-stagger--boryszew{
  transition-delay: calc(var(--RS-anim-delay) * 14);
}


.RS-anim-how-it-works-upload{
  animation: animHowItWorksUpload 1.5s linear infinite;
}

@keyframes animHowItWorksUpload{
  0%{
    transform: translateY(0);
  }
  30%,70%{
    transform: translateY(-20%);
  }
  100%{
    transform: translateY(0);
  }
}

.RS-anim-how-it-works-analyse{
  animation: animHowItWorksAnalyse 3s linear infinite;
}

@keyframes animHowItWorksAnalyse{
  33%,100%{
    transform: rotateZ(360deg);
  }
}

.RS-anim-how-it-works-recommendations{
  animation: animHowItWorksRecommendations 1.75s linear infinite;
}

@keyframes animHowItWorksRecommendations{
  0%{
    transform: translateX(100%);
    opacity: 0;
  }
  25%,75%{
    transform: translateX(0%);
    opacity: 1;
  }
  100%{
    transform: translateX(0);
    opacity: 0;
  }
}

.RS-anim-how-it-works-exchange{
  animation: animHowItWorksExchange 4.5s linear infinite;
}

@keyframes animHowItWorksExchange{
  0%,10%{
    transform: rotateZ(0deg);
  }
  20%,30%{
    transform: rotateZ(-90deg);
  }
  40%,50%{
    transform: rotateZ(-180deg);
  }
  60%,70%{
    transform: rotateZ(-270deg);
  }
  80%,100%{
    transform: rotateZ(-360deg);
  }
}

.RS-anim-how-it-works-compare{
  animation: animHowItWorksCompare 2s linear infinite;
}

@keyframes animHowItWorksCompare{
  0%,30%{
    transform: scale(1);
    /* opacity: 0; */
  }
  50%{
    transform: scale(.9);
    /* opacity: 1; */
  }
  70%,100%{
    transform: scale(1);
    /* opacity: 0; */
  }
}

.RS-anim-how-it-works-compare--second{
  animation-delay: calc(2s * 0.3);
}

.RS-anim-how-it-works-compare--third{
  animation-delay: calc(2s * 0.6);
}


.RS-looped-animation{
  animation-play-state: paused!important;
  backface-visibility: hidden;
  will-change: transform;
}

.RS-looped-animation--running{
  animation-play-state: running!important;
}