/* Fonts */

@font-face {
  font-family: 'InstrumentSans-Variable';
  /* src: url('static/InstrumentSans-Variable.woff2') format('woff2'),
       url('static/InstrumentSans-Variable.woff') format('woff'),
       url('static/InstrumentSans-Variable.ttf') format('truetype'); */
  src: url('../../static/InstrumentSans-Variable.woff2') format('woff2'),
       url('../../static/InstrumentSans-Variable.woff') format('woff'),
       url('../../static/InstrumentSans-Variable.ttf') format('truetype');
  font-weight: 100 1000;
  font-stretch: 75% 125%;
  font-display: swap;
}