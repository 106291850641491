/* Top Bar */

/* 
Todo: Parcel as of now does not import @custom-media rules from any other files and fails to build if @custom-media is not defined explicitly in every CSS file that makes use of it, the below is a test to confirm, other rules get imported
@import url("custom-media.css");
 */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-top-bar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 666;
  width: 100%;
  padding: var(--RS-space-24);
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  @media (--md-viewport) {
    padding: var(--RS-space-24) var(--RS-space-32);
   
  }
}

.RS-top-bar:before{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: 100%;
  /* background-image: linear-gradient(180deg, rgba(16, 15, 15,.89) 0%, rgba(16, 15, 15,.34) 67%, rgba(16, 15, 15,0) 100%); */
  z-index: -1;
  background: var(--RS-white-color);
  opacity: 1;
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1), transparent 100%);
}

.RS-top-bar--hidden {
  transform: translate3d(0, -100%, 0);
}

.RS-top-bar__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: var(--RS-col-8);
  height: 32px;
  margin: 0 auto;
}

.RS-top-bar__brand{
  z-index: 1;
  display: flex;
  gap: calc(var(--RS-space-16) + var(--RS-space-12));
  justify-content: center;
  align-items: center;
}

.RS-top-bar__logo {
  width: calc(var(--RS-space-128) + var(--RS-space-32) + var(--RS-space-2));
  height: calc(var(--RS-space-32) - var(--RS-space-2));
  /* margin: 0 var(--RS-space-2) 0 0; */

  @media (--md-viewport) {
    width: calc(var(--RS-space-128) + var(--RS-space-32) + var(--RS-space-12));
    height: calc(var(--RS-space-32) + var(--RS-space-8));
    /* margin: 0 var(--RS-space-8) 0 0; */
  }
}

.RS-top-bar__logo--boryszew{
  width: calc(var(--RS-space-128) - var(--RS-space-24));
  height: calc(var(--RS-space-48) - var(--RS-space-8));

  @media (--md-viewport) {
    width: calc(var(--RS-space-128) - var(--RS-space-6));
    height: calc(var(--RS-space-48) - var(--RS-space-2));
  }
}

.RS-top-bar__logo--boryszew:before{
  content: '';
  position: absolute;
  width: var(--RS-space-2);
  height: var(--RS-space-32);
  background: var(--RS-light-grey-color);
  display: block;
  left: var(--RS-space-16-n);
  top: 50%;
  transform: translateY(-50%);
}

.RS-top-bar__logo path{
  fill: var(--RS-black-color);
}

.RS-top-bar__nav {
    height: 100vh;
    width: 100vw;
    background: var(--RS-brand-color);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    position: fixed;
    top: var(--RS-space-24-n);
    left: var(--RS-space-24-n);
    gap: var(--RS-space-32);
    transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
    transform: translateX(100%);
    font-size: var(--RS-font-size-mobile-nav);

  @media (--md-viewport) {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    height: inherit;
    gap: calc(var(--RS-space-32) + var(--RS-space-4));
    width: unset;
    font-size: inherit;
    background: unset;
    position: relative;
    top: unset;
    left: unset;
    margin-left: auto;
    transform: none;
    font-size: var(--RS-font-size-top-nav);
  }
}

.RS-top-bar__nav--displayed{
  display: flex;
}

.RS-top-bar__nav--opened{
  transform: translateX(0%);
}

.RS-top-bar__nav > .RS-link{
  padding-bottom: var(--RS-space-4);
  opacity: 0;
  transform: translateY(var(--RS-space-32-n));
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  font-weight: var(--RS-font-weight-variable-bold);
  letter-spacing: .25px;
  

  @media (--md-viewport) {
    opacity: 1;
    transform: none;
    font-weight: var(--RS-font-weight-variable-bold);
  }
}

.RS-top-bar__nav > .RS-link--visible{
  opacity: 1;
  transform: none;
}

.RS-top-bar__nav > .RS-link:nth-of-type(1){
  transition-delay: calc(var(--RS-anim-delay)*1);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(2){
  transition-delay: calc(var(--RS-anim-delay)*2);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(3){
  transition-delay: calc(var(--RS-anim-delay)*3);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(4){
  transition-delay: calc(var(--RS-anim-delay)*4);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(5){
  transition-delay: calc(var(--RS-anim-delay)*5);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(6){
  transition-delay: calc(var(--RS-anim-delay)*6);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(7){
  transition-delay: calc(var(--RS-anim-delay)*7);
  @media (--md-viewport) {
    transition-delay: unset;
  }
}

.RS-top-bar__mobile-nav-icon {
  display: block;
  margin-left: auto;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  
  @media (--md-viewport) {
    display: none;
  }
}

.RS-top-bar__mobile-nav-icon span{
  width: calc(var(--RS-space-32) + var(--RS-space-1));
  height: var(--RS-space-4);
  background-color: var(--RS-black-color);
  display: block;
  margin: var(--RS-space-6) auto;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform-origin: center;
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(1){
  transform: rotateZ(45deg) translateY(calc(var(--RS-space-6) + 1px));
}


.RS-top-bar__mobile-nav-icon span:nth-of-type(2){
  /* transform: translateX(var(--RS-space-6-n)); */
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(2){
  transform: rotateZ(-45deg) translateY(calc(var(--RS-space-6-n) - 1px));
}

/* @keyframes showTopBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

