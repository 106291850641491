/* Intro */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-intro {
  position: relative;
  z-index: 0;
  padding: 12vh var(--RS-space-24) 4vh;
  display: flex;
  align-items: flex-end;
  height: 90vh;

  @media (--h-mobile-viewport) {
    height: auto;
    min-height: 100vh;
  }

  @media (--lg-viewport) {
    /* height: 100vh; */
    min-height: 90vh;
    padding: 14vh var(--RS-space-32) 8vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro--contact {
  position: relative;
  z-index: 0;
  padding: 20vh var(--RS-space-24) 4vh;
  display: flex;
  align-items: flex-end;
  height: auto;

  @media (--h-mobile-viewport) {
    height: auto;
    min-height: 100vh;
  }

  @media (--lg-viewport) {
    /* height: 100vh; */
    min-height: 100vh;
    padding: 14vh var(--RS-space-32) 14vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro__container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  margin: 0 auto;
  height: 100%;

  @media (--lg-viewport) {
    /* flex-direction: row-reverse; */
  }

}

.RS-intro__container--contact{
  justify-content: flex-start;
}

.RS-intro__container > .RS-article:first-of-type{
  padding-bottom: 15vh;
}

.RS-intro-product-mock-wrapper{
  width: 100%;
  max-width: calc(var(--RS-space-256) + var(--RS-space-256));
  background: var(--RS-white-color);
  /* background: var(--RS-black-color); */
  box-shadow: inset 0 0 0 1px #FFF, 0px 23px 18px 0px rgba(16, 17, 22, 0.04);
  /* box-shadow: inset 0 0 0 1px #000, 0px 23px 18px 0px rgba(16, 17, 22, 0.04); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  border-radius: var(--RS-space-6) var(--RS-space-6) var(--RS-space-12) var(--RS-space-12);
  text-align: left;
  margin-bottom: var(--RS-space-24);
  /* position: absolute; */
  left: 50%;
  /* transform: translateX(-50%) translateY(calc(-100% - var(--RS-space-24))); */
}

.RS-intro-product-mock-top{
  display: flex;
  padding: var(--RS-space-8) var(--RS-space-16);
  border-radius: var(--RS-space-6);
  box-shadow: inset 0 0 0 1px #FFF, 0px 23px 18px 0px rgba(16, 17, 22, 0.03);
  /* box-shadow: inset 0 0 0 1px #000, 0px 23px 18px 0px rgba(16, 17, 22, 0.03); */
  gap: var(--RS-space-8);
  z-index: 2;
}

.RS-intro-product-mock-top > span{
  width: calc(var(--RS-space-8) + var(--RS-space-2));
  height: calc(var(--RS-space-8) + var(--RS-space-2));
  border-radius: 50%;
  background-color: var(--RS-light-grey-color);
  opacity: 1;
  /* opacity: .13; */
}

.RS-intro-product-mock-bottom{
  padding: var(--RS-space-12) var(--RS-space-16) var(--RS-space-16) var(--RS-space-16);
  display: flex;
  justify-content: space-between;
  position: relative;

  @media (--lg-viewport){
    padding: var(--RS-space-16) var(--RS-space-24) var(--RS-space-24) var(--RS-space-24);
  }
}

.RS-intro-product-mock-bottom .RS-intro-product-mock__col{
  display: flex;
  flex-direction: column;
  gap: var(--RS-space-8);
}

.RS-intro-product-mock__col--first{
  width: 35%;
  @media (--md-viewport){
    width: 40%;
  }
}

.RS-intro-product-mock__col--second{
  width: 35%;
  @media (--md-viewport){
    width: 40%;
  }
}

.RS-intro-product-mock__col--third{
  width: 30%;
  @media (--md-viewport){
    width: 20%;
  }
}

.RS-intro-product-mock-bottom > .RS-intro-product-mock__col > p:first-of-type{
  font-size: var(--RS-font-size-h3);
  font-weight: var(--RS-font-weight-variable-bold);
  /* font-weight: var(--RS-font-weight-variable-regular); */
  opacity: .6;
  color: var(--RS-light-grey-color-alt);
  /* color: var(--RS-white-color); */
}

.RS-intro-product-mock-bottom > .RS-intro-product-mock__col > p:last-of-type{
  font-size: var(--RS-font-size-h2);
  font-weight: var(--RS-font-weight-variable-bold);
  display: flex;
  gap: var(--RS-space-8);
  align-items: center;
  color: var(--RS-black-color);
  /* color: var(--RS-white-color); */

  @media(--lg-viewport){
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-intro-product-mock-bottom > .RS-intro-product-mock__col > p:first-of-type > span:first-of-type{
  display: none;

  @media(--md-viewport){
    display: inline;
  }
}

.RS-intro-product-mock-bottom .RS-intro-product-mock-bottom__icon{
  background-color: var(--RS-brand-color);
  width: calc(var(--RS-space-24) - var(--RS-space-4));
  height: calc(var(--RS-space-24) - var(--RS-space-4));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--RS-space-4);
  box-shadow: inset 0 0 0 1px #FFF;
  overflow: hidden;
  flex-shrink: 0;
}

.RS-intro-product-mock-bottom .RS-intro-product-mock-bottom__icon svg{
  width: calc(var(--RS-space-24) - var(--RS-space-8));
  height: calc(var(--RS-space-24) - var(--RS-space-8));
  /* animation: loopArrowTopLeftBottomRight var(--RS-default-transition) 1.75s infinite; */
  animation: loopArrowBottomLeftTopRight var(--RS-default-transition) 1.75s infinite;
}

@keyframes loopArrowTopLeftBottomRight {
  0% {
    transform: translateY(-100%) translateX(-100%);
  }
  50% {
    transform: translateY(0px) translateX(0px);
  }
  100% {
    transform: translateY(100%) translateX(100%);
  }
}

@keyframes loopArrowBottomLeftTopRight {
  0% {
    transform: translateY(100%) translateX(-100%);
  }
  50% {
    transform: translateY(0px) translateX(0px);
  }
  100% {
    transform: translateY(-100%) translateX(100%);
  }
}

.RS-intro-product-mock-bottom .RS-intro-product-mock-bottom__icon svg path{
  fill: var(--RS-black-color);
}

.RS-intro-product-mock-bottom__overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--RS-white-color);
  opacity: 0;
  /* transition: opacity var(--RS-anim-duration-lg) ease-in; */
  transition: opacity var(--RS-anim-duration-lg) cubic-bezier(0.445, 0.050, 0.550, 0.950);
  border-radius: var(--RS-space-6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--RS-space-12);
}

.RS-intro-product-mock-bottom__overlay--visible{
  opacity: 1;
}

.RS-intro-product-mock-bottom__overlay > div{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--RS-space-4);
}

.RS-intro-product-mock-bottom__overlay > div > span{
  width: var(--RS-space-2);
  height: var(--RS-space-16);
  background: var(--RS-dark-grey-color);
  transform-origin: center;
  opacity: 0;
  transition: opacity var(--RS-anim-duration-md) var(--RS-default-transition);
}

.RS-intro-product-mock-bottom__overlay--visible > div > span{
  opacity: 1;
  animation: animScaleHeight var(--RS-default-transition) var(--RS-anim-duration-2xlg) infinite;
}

@keyframes animScaleHeight {
  0% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.5);
  }
}

.RS-intro-product-mock-bottom__overlay > div > span:nth-of-type(1){
  /* transform: scaleY(0.7); */
}

.RS-intro-product-mock-bottom__overlay > div > span:nth-of-type(2){
  animation-delay: calc(var(--RS-anim-delay) * 2);
}

.RS-intro-product-mock-bottom__overlay > div > span:nth-of-type(3){
  animation-delay: calc(var(--RS-anim-delay) * 4);
}

.RS-intro-product-mock-bottom__overlay > div > span:nth-of-type(4){
  animation-delay: calc(var(--RS-anim-delay) * 6);
}

.RS-intro-product-mock-bottom__overlay > div > span:nth-of-type(5){
  animation-delay: calc(var(--RS-anim-delay) * 8);
}

.RS-intro-product-mock-bottom__overlay > p{
  font-size: var(--RS-font-size-disclaimer);
  /* font-weight: var(--RS-font-weight-variable-regular); */
  font-weight: var(--RS-font-weight-variable-bold);
  color: var(--RS-light-grey-color-alt);
  /* color: var(--RS-dark-grey-color); */
  opacity: 0.6;
  /* opacity: 1; */
}


.RS-intro-product-mock-price{
  transition: opacity var(--RS-anim-duration-sm) linear, transform var(--RS-anim-duration-xlg) var(--RS-default-transition);
}

.RS-intro-product-mock-price--hidden{
  opacity: 0;
  transform: translateY(-33%);
}

.RS-intro-product-mock-price--market{
  animation-delay: calc(var(--RS-anim-delay) * 4);
}

@media(--md-viewport){
  :root{
    --RS-intro-product-mock-cursor-offset-x: 7.5vw;
    --RS-intro-product-mock-cursor-offset-y: -15vh;
  }
}


.RS-intro-product-mock-cursor{
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--RS-space-64);
  height: var(--RS-space-48);
  opacity: 1;
  opacity: 0;
  border-radius: var(--RS-space-6);
  box-shadow: inset 0 0 0 1px #FFF, 0px 23px 18px 0px rgba(16, 17, 22, 0.03);
  background-color: var(--RS-white-color);
  padding: var(--RS-space-16) var(--RS-space-12);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity var(--RS-anim-duration-sm) linear, transform var(--RS-anim-duration-2xlg) var(--RS-default-transition);
  z-index: 3;
}

.RS-intro-product-mock-cursor--first{
  transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -2 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
  /* transform: scale(1); */
}

.RS-intro-product-mock-cursor--first.RS-intro-product-mock-cursor--visible{
  animation: animCursorVisibilityFirst var(--RS-default-transition) 2000ms 1;
}

@keyframes animCursorVisibilityFirst {
  0% {
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -2 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
  }
  15%{
    opacity: 1;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -2 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(.85);
  }
  45% {
    transform: translate(-50%, -33%) scale(1);
    opacity: 1;
  }
  55% {
    opacity: 1;
    transform: translate(-50%, -33%) scale(.5);
  }
  60%,75%{
    opacity: 0;
    transform: translate(-50%, -33%) scale(.5);
  }
  80%,85%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -2 - 50%), -16vh);
  }
  100%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -2 - 50%), var(--RS-intro-product-mock-cursor-offset-y));
  }
}

.RS-intro-product-mock-cursor--second{
  transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -1 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
}

.RS-intro-product-mock-cursor--second.RS-intro-product-mock-cursor--visible{
  animation: animCursorVisibilitySecond var(--RS-default-transition) 2000ms 1;
}

@keyframes animCursorVisibilitySecond {
  0% {
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -1 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
  }
  15%{
    opacity: 1;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -1 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(.85);
  }
  45% {
    transform: translate(-50%, -33%) scale(1);
    opacity: 1;
  }
  55% {
    opacity: 1;
    transform: translate(-50%, -33%) scale(.5);
  }
  60%,75%{
    opacity: 0;
    transform: translate(-50%, -33%) scale(.5);
  }
  80%,85%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -1 - 50%), -16vh);
  }
  100%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * -1 - 50%), var(--RS-intro-product-mock-cursor-offset-y));
  }
}

.RS-intro-product-mock-cursor--third{
  transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 0 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
}

.RS-intro-product-mock-cursor--third.RS-intro-product-mock-cursor--visible{
  animation: animCursorVisibilityThird var(--RS-default-transition) 2000ms 1;
}

@keyframes animCursorVisibilityThird {
  0% {
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 0 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
  }
  15%{
    opacity: 1;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 0 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(.85);
  }
  45% {
    transform: translate(-50%, -33%) scale(1);
    opacity: 1;
  }
  55% {
    opacity: 1;
    transform: translate(-50%, -33%) scale(.5);
  }
  60%,75%{
    opacity: 0;
    transform: translate(-50%, -33%) scale(.5);
  }
  80%,85%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 0 - 50%), -16vh);
  }
  100%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 0 - 50%), var(--RS-intro-product-mock-cursor-offset-y));
  }
}

.RS-intro-product-mock-cursor--fourth{
  transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 1 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
}

.RS-intro-product-mock-cursor--fourth.RS-intro-product-mock-cursor--visible{
  animation: animCursorVisibilityFourth var(--RS-default-transition) 2000ms 1;
}

@keyframes animCursorVisibilityFourth {
  0% {
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 1 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
  }
  15%{
    opacity: 1;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 1- 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(.85);
  }
  45% {
    transform: translate(-50%, -33%) scale(1);
    opacity: 1;
  }
  55% {
    opacity: 1;
    transform: translate(-50%, -33%) scale(.5);
  }
  60%,75%{
    opacity: 0;
    transform: translate(-50%, -33%) scale(.5);
  }
  80%,85%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 1 - 50%), -16vh);
  }
  100%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 1 - 50%), var(--RS-intro-product-mock-cursor-offset-y));
  }
}

.RS-intro-product-mock-cursor--fifth{
  transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 2 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
}

.RS-intro-product-mock-cursor--fifth.RS-intro-product-mock-cursor--visible{
  animation: animCursorVisibilityFifth var(--RS-default-transition) 2000ms 1;
}

@keyframes animCursorVisibilityFifth {
  0% {
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 2 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(1);
  }
  15%{
    opacity: 1;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 2 - 50%), var(--RS-intro-product-mock-cursor-offset-y)) scale(.85);
  }
  45% {
    transform: translate(-50%, -33%) scale(1);
    opacity: 1;
  }
  55% {
    opacity: 1;
    transform: translate(-50%, -33%) scale(.5);
  }
  60%,75%{
    opacity: 0;
    transform: translate(-50%, -33%) scale(.5);
  }
  80%,85%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 2 - 50%), -16vh);
  }
  100%{
    opacity: 0;
    transform: translate(calc(var(--RS-intro-product-mock-cursor-offset-x) * 2 - 50%), var(--RS-intro-product-mock-cursor-offset-y));
  }
}

.RS-intro-product-mock-cursor > svg{
  width: var(--RS-space-64);
  height: var(--RS-space-32);
}

.RS-intro-product-mock-cursor:after{
  content: 'FV';
  position: absolute;
  top: var(--RS-space-4);
  left: var(--RS-space-6);
  font-size: 7px;
  font-weight: var(--RS-font-weight-variable-bold);
  font-weight: 900;
  letter-spacing: 0.5px; 
}