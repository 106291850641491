/* Header */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-header {
  @media (--lg-viewport) {
    /* max-width: var(--RS-col-4); */
  }
}

.RS-header--centered {
  text-align: center;
}

.RS-header--wide {

  @media (--md-viewport) {
    max-width: var(--RS-col-7);
  }
}

.RS-header--spaced{
  margin: var(--RS-space-48) auto;

  @media (--md-viewport) {
    margin: 0 auto var(--RS-space-64);
  }
}

.RS-header--social {
  @media (--md-viewport) {
    max-width: var(--RS-col-6);
  }
}

.RS-header--decor{
  position: relative;
}

.RS-header--decor:before{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top:0;
  width: calc(var(--RS-font-size-h1-desktop) * 3);
  height: calc(var(--RS-font-size-h1-desktop) * 3);
  transform: translate(-33%, -33%) rotateZ(-20deg);
  border-radius: 50%;
  border: var(--RS-space-8) solid var(--RS-brand-color);
  border-right-color: transparent;
  border-bottom-color: transparent;
  /* border-top-right-radius: calc(var(--RS-font-size-h1-desktop) * 3 + var(--RS-space-8)); */
  /* box-shadow: var(--RS-space-8-n) var(--RS-space-8-n) 0 0 var(--RS-brand-color); */
  /* background-color: var(--RS-brand-color); */
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition);
}

.RS-header--decor.RS-header--decor-visible:before{
  transform: translate(-33%, -33%) rotateZ(0deg);
}

.RS-header__headline {
  margin: 0 0 var(--RS-space-12);
  font-size: var(--RS-font-size-h3);
  text-transform: uppercase;
  letter-spacing: 0.15em;
  display: inline-flex;

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h3);
  }
}

.RS-header__description {
  font-size: var(--RS-font-size-h1);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-bold);

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h1-desktop);
    line-height: var(--RS-line-height-md);
  }
}

/* .RS-header__description--centered{
  text-align: center;
} */

.RS-header__description + .RS-header__description{
  margin: var(--RS-space-32) 0 0 0;
}

.RS-header__description + .RS-header__description--secondary{
  margin: var(--RS-space-48) 0 0 0;
}

.RS-header__description + .RS-button{
  margin: var(--RS-space-32) 0 0 0;
}

.RS-header__description--secondary {
  font-size: var(--RS-font-size-h2);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-bold);

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h2-desktop);
  }
}



.RS-header__headline + .RS-header__description--secondary{
  margin: var(--RS-space-24) 0 0 0;
}

.RS-header__description-link {
  color: inherit;
  position: relative;
  transition: opacity var(--RS-anim-duration-sm) var(--RS-default-transition);
  display: inline-flex;
}

.RS-header__description-link:hover {
  box-shadow: inset 0 0 0 0 transparent;
  opacity: 1;
}

.RS-header__description-link:before{
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  left: 0;
  background-color: var(--RS-pure-white-color);
  transform-origin: left;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition);
}

.RS-header__description-link:hover:before{
  transform-origin: right;
  transform: scaleX(0);
  background-color: var(--RS-primary-button-color);
}

.RS-header .RS-header__button {
  margin: var(--RS-space-32) 0 0;

  @media (--md-viewport) {
    margin: var(--RS-space-48) 0 0;
  }
}

.RS-header__description {
  transition-duration: calc(var(--RS-anim-duration-xlg) + var(--RS-anim-duration-lg));
}

.RS-header .RS-header__link,
.RS-header__artwork {
  transition-duration: 1.5s;
}

.RS-header-secondary-icon{
  transform: translateY(var(--RS-space-6));
  display: inline-flex;
}

.RS-header-secondary-icon > svg{
  height: calc(var(--RS-space-24) + var(--RS-space-2));
  width: calc(var(--RS-space-24) + var(--RS-space-2));

  @media (--md-viewport) {
    height: calc(var(--RS-space-32) + var(--RS-space-2));
    width: calc(var(--RS-space-32) + var(--RS-space-2));
  }
}