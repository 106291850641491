/* Article */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-article {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  max-width: var(--RS-col-6);
  /* text-align: left; */
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  

  @media (--lg-viewport) {
  }
}

.RS-article--centered{
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.RS-article--block{
  display: block;
}

.RS-article--cols{
  gap: var(--RS-space-24);
  /* align-items: center; */

  @media (--lg-viewport) {
    flex-direction: row;
    gap: var(--RS-space-48);
  }
}

.RS-article--reverse {
  @media (--lg-viewport) {
    flex-direction: row-reverse;
  }
}

.RS-article--wide{
  
  max-width: 100%;

  @media (--lg-viewport) {
    max-width: var(--RS-col-8);
  }
}


.RS-article--tiles-stacked{
  gap: 0;

  @media (--lg-viewport) {
    gap: 0;
  }
}

.RS-article--tiles-stacked > .RS-header{
  width: 100%;

  @media (--lg-viewport) {
    width: 30%;
  }
}

.RS-article--tiles-stacked > .RS-content{
  width: 100%;

  @media (--lg-viewport) {
    width: 70%;
  }
}

.RS-article > svg {
  height: auto;
  margin: 0 0 var(--RS-space-48);

  @media (--md-viewport) {
    margin: 0 0 var(--RS-space-64);
  }

  @media (--lg-viewport) {
    margin: 0;
  }
}

.RS-article + .RS-article{
  margin-top: var(--RS-space-96);
}
